








import { Component, Vue } from "vue-property-decorator";
import FormularioResultadosComponent from "@/components/FormularioEscolar/FormularioResultadosComponent.vue";

@Component({
  components: {
    FormularioResultadosComponent
  }
})
export default class FormularioResultadoView extends Vue {}
